import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import printJS from 'print-js';
import { Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { RentalService } from 'src/app/services/Rental.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-rental-confirmation',
  templateUrl: './rental-confirmation.component.html',
  styleUrls: ['./rental-confirmation.component.scss']
})
export class RentalConfirmationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    public rentalService: RentalService,
    private router: Router,
    private marketInfoService: MarketInfoService,) { }

  marketId;
  imageUrl;
  private subscriptions: Subscription[] = [];
  @ViewChild('printf') receiptIframe: ElementRef;
  modalRef: BsModalRef;
  @ViewChild('printReceiptPopup', { static: true }) printReceipt: ModalDirective;
  @Input() VendorId;
  @Input() AccountReceipId;
  @Input() RentalType;
  @Input() newVendor;
  @Input() SignBase64;
  MarketName = "";
  VendorName = "";
  AuthCode = "";
  MarketAccountReceiptId= "";

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
    });
    this.getMarketLogo();
    this.getVendorPaymentDetails();
  }
  
  getMarketLogo() {
    const timestamp = new Date().getTime();
    this.subscriptions.push(this.marketInfoService.getMarketLogo(this.marketId).subscribe((res: any) => {
      if (res) {
        this.imageUrl = res.MarketLogoBlobURL + `?timestamp=${timestamp}`;
      }
      else {
        this.imageUrl = '../../../assets/images/NoImage.jpg'
      }
    }));
  }

  getVendorPaymentDetails() {
    this.subscriptions.push(this.rentalService.getVendorPaymentDetails(this.VendorId, this.marketId, this.AccountReceipId).subscribe((res: any) => {
      if (res) {
        this.MarketName = res.MarketName
        this.VendorName = res.VendorName;
        this.AuthCode = res.PaymentAuthorizationNumber;
        this.MarketAccountReceiptId = res.MarketAccountReceiptId
      }
      this.emailRentalReceipts();
    }));
  }

  emailRentalReceipts() {
    const params = {
      VendorId: parseInt(this.VendorId),
      AccountReceiptId: parseInt(this.AccountReceipId),
      PaymentOnly: false,
      RentalType: this.RentalType,
      SignBase64 : this.SignBase64
    };
    var emailSubject = this.RentalType == "1" ? "Daily Rental Receipt" :
      this.RentalType == "2" ? "Monthly Rental Receipt" :
        this.RentalType == "3" ? "Weekly Rental Receipt" :
          this.RentalType == "4" ? "Combined Monthly Rental Receipt" :
            this.RentalType == "5" ? "Combined Weekly Rental Receipt" : "Rental Receipt";
    this.rentalService.generatePaymentReceipt(this.marketId, params).subscribe((res1: any) => {
      if (res1.Success) {
        var emailData = [{
          AccountReceiptID: this.AccountReceipId,
          VendorID: parseInt(this.VendorId),
          BaseTemplateID: 11,
          AttachmentFile: res1.Message,
          AttachmentFileName: "Rental Receipt",
          FileType: "application/pdf",
          Subject: emailSubject,
          WebRentalRequest: true
        }];

        this.subscriptions.push(this.rentalService.ComposeEmails(this.marketId,emailData).subscribe((emailResponse: any) => {
          console.log("Payment receipt email delivered");
        }));
      }
    });
  }

  goBack(){
    if(this.newVendor){
      this.router.navigate(['/login', this.marketId]);
    }else{
      this.router.navigate(['./rent-online', this.marketId, this.VendorId, 'br',this.RentalType]);
    }
  }

  PreviewRentalReceipt() {    
    const params = {
      VendorId: parseInt(this.VendorId),
      AccountReceiptId: parseInt(this.AccountReceipId),
      PaymentOnly: false,
      RentalType: this.RentalType,
      SignBase64 : this.SignBase64
    };

    this.rentalService.generatePaymentReceipt(this.marketId, params).subscribe((res1: any) => {
      if (res1.Success) {
        const message = res1.Message;
        const iframe = this.receiptIframe.nativeElement;
        iframe.src = message;
        iframe.style.height = '700px';
        iframe.style.width = '100%';
        this.printReceipt.show();
      }
    })
  }

  isMobile(): boolean {
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  base64ToBlob(base64: string, contentType: string) {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  printRentalReceipt() {
    const params = {
      VendorId: parseInt(this.VendorId),
      AccountReceiptId: parseInt(this.AccountReceipId),
      PaymentOnly: false,
      RentalType: this.RentalType,
      SignBase64 : this.SignBase64
    };
    this.rentalService.generatePaymentReceipt(this.marketId,params).subscribe((res1: any) => {
      if (res1.Success) {
        const message = res1.Message;
        if (!this.isMobile()) {
          printJS({
            printable: message.toString().split(',')[1],
            type: 'pdf',
            base64: true,
            onPrintDialogClose: () => { },
          });
        }
        else {
          const base64String = res1.Message.replace("data:application/pdf;base64,", "");
          const blob = this.base64ToBlob(base64String, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          saveAs(blob, 'receipt.pdf');
        }
      }
    });
  }
}
